import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import { Link, Avatar, Box, Typography, Container, CssBaseline } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { useAuth } from "../common/hooks/useAuth";
import { Storage } from '../common/utils/Storage';
import PageNames from '../common/utils/PageNames';
import ShopConfig from '../common/api/config/shopConfig';

import { selectLoginUsingLDAP, selectLoginUsingIAM, selectOnFailedIAMShowLogin, getSettings, selectOrganisationLogo } from '../common/store/settingsSlice'
import { usePageLayoutConfigurator } from "../common/hooks/usePageLayoutConfigurator";

import TextField from '../common/ui/qvshop/components/TextField';
import ReactRouterLink from '../common/ui/qvshop/components/core/ReactRouterLink';
import PrimaryButton from '../common/ui/qvshop/components/core/PrimaryButton';
import LocationsPopupDialog from '../common/ui/qvshop/components/popups/firstLogin/LocationsPopupDialog';
import NoLocationsPopupDialog from '../common/ui/qvshop/components/popups/firstLogin/NoLocationsPopupDialog';

import { getUserWarehouseLocations, selectUserHasLocations, selectShowHelpPopup, setShowHelpPopup, saveDontShowHelpPopup, selectIsLoadingUserLocations } from '../common/store/locationsSlice';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link href="https://netzinkubator.de/">
                Netzinkubator.de
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function LoginView() {
    const dispatch = useDispatch();
    usePageLayoutConfigurator(PageNames.LOGIN);
    const { t } = useTranslation(['login']);
    // const token = Storage.getAuthToken();

    const locationsAreLoading = useSelector(selectIsLoadingUserLocations);
    const showHelpPopup = useSelector(selectShowHelpPopup);
    const userHasLocations = useSelector(selectUserHasLocations);

    const loginUsingLDAP = useSelector(selectLoginUsingLDAP);
    const loginUsingIAM = useSelector(selectLoginUsingIAM);
    const onFailedIAMShowLogin = useSelector(selectOnFailedIAMShowLogin);

    const organisationLogo = useSelector(selectOrganisationLogo);

    const showPasswordForgotten = !loginUsingIAM && !loginUsingLDAP;

    const { onLogin, onLogout, doIAMLogin, token, setToken } = useAuth();

    const [error, setError] = useState(false);
    const [lastCallTimestamp, setLastCallTimestamp] = useState(0);

    useEffect(() => {
        if(loginUsingIAM) {
            async function doLoginUsingIAM() {
                // do not reset isIAMUser - this is used on app start to not retrieve the warehouses (if isIAMUser == true) -> which will redirect to the login page
                // so that the auth over IAM can be done on each start of the app
                // also do not reset the cart
                setToken("");
                Storage.setLocationId("0");
                Storage.resetLoggedIn();

                const { success, message } = await doIAMLogin();

                if(success) {
                    onSuccessLogin();
                } else {
                    // todo: add option to show error message or login form
                    if(message === "shibboleth_not_allowed" && onFailedIAMShowLogin) {
                        // do not set error message
                    } else {
                        setError(t("login.error"+message));
                    }
                }
            }

            const currentTimestamp = Date.now();
            if (currentTimestamp - lastCallTimestamp >= 5000) {
                setLastCallTimestamp(currentTimestamp);

                console.log("calling doLoginUsingIAM");
                doLoginUsingIAM();
            } else {
                console.log("ignore call to doLoginUsingIAM - within 5s");
            }
        }
    }, [loginUsingIAM])

    const handleHelpPopupClose = (isChecked) => {
        dispatch(setShowHelpPopup(false));
        if(isChecked) {
            dispatch(saveDontShowHelpPopup(false));
        }
    }

    const handleNoLocationsPopupClose = () => {
        onLogout();
    }

    const onSuccessLogin = () => {
        // Settings can change depending on user logged in, so reload settings on login
        dispatch(getSettings());
        dispatch(getUserWarehouseLocations());
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const { success, message } = await onLogin({
            username: data.get('username'),
            password: data.get('password'),
        });

        if(success) {
            onSuccessLogin();
        } else {
            setError(t("login.error" + message));
        }
    };

    const hasToken = token !== null && token !== undefined && token !== "";

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            { (!hasToken || !locationsAreLoading) && 
                <>
                    <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                    { organisationLogo === "" ?
                        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        :
                        <Box
                            component="img"
                            alt="OrganisationLogo"
                            src={ShopConfig.config().getURLFor(organisationLogo)}
                            sx={{ maxWidth: "300px", objectFit: "contain" }}
                        />
                    }
                    <Typography sx={{ mt: 3}} component="h1" variant="h5">
                        {t("page.title")}
                    </Typography>
                    {loginUsingIAM && !onFailedIAMShowLogin ? 
                        <>
                            { error && 
                                <Typography sx={{ mt: 3 }} component="h1" variant="h5" color="error">
                                    {error}
                                </Typography>
                            }
                        </>
                        :
                        // !loginUsingIAM
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label={t("label.username")}
                                name="username"
                                autoComplete="username"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={t("label.password")}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />

                            { error && <Typography component="h2" variant="body2" color="error">
                                {error}
                            </Typography> }
                            <PrimaryButton type="submit" fullWidth sx={{ mt: 3, mb: 2 }} label={t("button.login")} />
                            <Grid container>
                                { showPasswordForgotten && 
                                <Grid xs>
                                    <ReactRouterLink underline="none" to="/passwordForgotten">{t("link.forgotPassword")}</ReactRouterLink>
                                </Grid>
                                }
                                <Grid >
                                    <ReactRouterLink underline="none" to="/register">{t("link.notRegisteredYet")}</ReactRouterLink>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </> }
            { hasToken && showHelpPopup && ( userHasLocations ? 
                <LocationsPopupDialog open={showHelpPopup} setOpen={handleHelpPopupClose} showActionBar title={t("login.firstLoginPopupTitle")} modal showLogo /> :
                <NoLocationsPopupDialog open={showHelpPopup} setOpen={handleNoLocationsPopupClose} showActionBar showAcceptTermsSection={false} dismissOnBackdropClick dismissOnEscapeKey title={t("login.firstLoginPopupTitle")} modal showLogo />) }
        </Container>
    );
}