import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { Api } from "../api/api";

const STATUS_IDLE = 'idle';
const STATUS_LOADING = 'loading';
const STATUS_SUCCEEDED = 'succeeded';
const STATUS_FAILED = 'failed';

const initialState = {
    status: STATUS_IDLE,
    error: null,

    settings: {},
};

export const getSettings = createAsyncThunk(
    'shop/getSettings',
    async (_noParams) => {
        const response = await Api.getShopSettings();
        return response;
    }
)

export const slice = createSlice({
    name: 'settings',
    initialState,

    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload;
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(getSettings.pending, (state) => {
                state.status = STATUS_LOADING;
            })
            .addCase(getSettings.fulfilled, (state, action) => {
                state.status = STATUS_SUCCEEDED;

                state.settings = action.payload;
            })
            .addCase(getSettings.rejected, (state, action) => {
                state.status = STATUS_FAILED;
                state.error = action.payload;
            })
    },
});

export const { setStatus } = slice.actions;

export const selectSettingsLoaded = state => state.settings.status === STATUS_SUCCEEDED;

export const selectLoginInCheckout = state => (state.settings.settings && state.settings.settings.login_only_in_checkout) || false;
export const selectLoginUsingLDAP = state => (state.settings.settings && state.settings.settings.login_using_ldap) || false;
export const selectLoginUsingIAM = state => (state.settings.settings && state.settings.settings.login_using_iam) || false;
export const selectOnFailedIAMShowLogin = state => (state.settings.settings && state.settings.settings.on_failed_iam_show_login) || false;

export const selectAllArticlesMustBeInTheSameInterval = state => (state.settings.settings && state.settings.settings.all_articles_in_same_interval) || false;

export const selectHasExtendedTimes = state => (state.settings.settings && state.settings.settings.extended_times) || false;
export const selectWorkingHours = state => state.settings.settings.working_hours;
export const selectSemesterSettings = state => state.settings.settings.semester_settings;
export const selectDaysToDisableAfterToday = state => state.settings.settings.days_to_disable_after_today || 0;

export const selectNoLocationsText = state => state.settings.settings.no_locations_text;
export const selectFirstLoginText = state => state.settings.settings.first_login_text;
export const selectHasFirstLoginText = state => state.settings.settings.first_login_text !== '';

export const selectWelcomeText = state => state.settings.settings.welcome_text;
export const selectHasWelcomeText = state => state.settings.settings.welcome_text && state.settings.settings.welcome_text !== '';
export const selectWelcomeImage = state => state.settings.settings.welcome_image;
export const selectHasWelcomeImage = state => state.settings.settings.welcome_image && state.settings.settings.welcome_image !== '';

export const selectOrganisationLogo = state => (state.settings.settings && state.settings.settings.organisation_logo) || "";
export const selectOrganisationName = state => (state.settings.settings && state.settings.settings.organisation_name) || "";
export const selectOrganisationCountry = state => (state.settings.settings && state.settings.settings.organisation_country) || "";

export const selectTermsAndConditionsLink = state => (state.settings.settings && state.settings.settings.terms_and_conditions_link) || "";
export const selectDataPrivacyLink = state => (state.settings.settings && state.settings.settings.data_privacy_link) || "";
export const selectImpressumLink = state => (state.settings.settings && state.settings.settings.impressum_link) || "";
export const selectContactLink = state => (state.settings.settings && state.settings.settings.contact_link) || "";
export const selectHelpPageLink = state => (state.settings.settings && state.settings.settings.help_page_link) || "";

export const selectShowRecurrentTab = state => (state.settings.settings && state.settings.settings.show_recurrent_tab) || false;

export const selectPricesEnabled = state => (state.settings.settings && state.settings.settings.prices_enabled) || false;
export const selectMandatoryCostCenterInCheckout = state => (state.settings.settings && state.settings.settings.mandatory_cost_center_in_checkout) || false;

export const selectCountries = state => state.settings.settings.countries ? Object.entries(state.settings.settings.countries).map(([id, name]) => {return {id, name}}) : [];

export default slice.reducer;