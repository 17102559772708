import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from "react-i18next";
import DOMPurify from 'dompurify';
import { useDebounceCallback } from '@react-hook/debounce';
import Debounce from '../common/utils/Debounce';

import { Stack, Typography, Box } from '@mui/material';

import { p1, py2, flexRowCentered, flexColCentered, flexColFullHeightScrollable } from "../common/ui/sx/common";
import ProductCard from '../common/ui/qvshop/components/ProductCard';
import NoLocationSelectedDialog from '../common/ui/qvshop/components/popups/NoLocationSelectedDialog';
import { usePageLayoutConfigurator } from '../common/hooks/usePageLayoutConfigurator';
import ProductCardSkeleton from '../common/ui/qvshop/components/ProductCardSkeleton';
import MasonrySection from '../common/ui/qvshop/components/MasonrySection';

import ShopConfig from '../common/api/config/shopConfig';
import PageNames from '../common/utils/PageNames';
import { CategoriesUtils } from '../common/utils/CategoriesUtils';

import { selectShowSearch } from '../common/store/layoutSlice';
import { selectNoLocationSelected, selectStartDate, selectEndDate, selectRecurrenceType, selectStartDateRecurring, selectEndDateRecurring, selectRepetitionInterval, selectRecurrenceStartDay, selectRecurrenceStartTime, selectRecurrenceEndDay, selectRecurrenceEndTime, selectRecurrenceHasErrors, selectProductsAreFiltered, selectCategory, setCategory, setCategoryPath, selectFromToHasErrors, selectRecurring } from '../common/store/filtersSlice';
import { selectHasLocationsDefined } from '../common/store/locationsSlice';
import { selectIsLoading, selectStatusApiHasFailed, getProducts, selectProducts, selectProductsCount, checkAvailabilityForProducts, selectTriggerProductsAvailability, getProductsCount } from '../common/store/productsSlice';
import { selectHasWelcomeImage, selectHasWelcomeText, selectWelcomeImage, selectWelcomeText } from '../common/store/settingsSlice';
import { selectCartHasItems } from '../common/store/cartSlice';
import { selectCategories } from '../common/store/categoriesSlice';
import { removeProduct } from '../common/store/productSlice';

export default function ImageMasonry() {
    usePageLayoutConfigurator(PageNames.PRODUCTS_LIST);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { categoryId } = useParams();
    const category = categoryId ? categoryId : '';

    const showWelcomeText = useSelector(selectHasWelcomeText);
    const welcomeText = useSelector(selectWelcomeText);
    const showWelcomeImage = useSelector(selectHasWelcomeImage);
    const welcomeImage = useSelector(selectWelcomeImage);

    const selectedCategory = useSelector(selectCategory);
    const categories = useSelector(selectCategories);

    const showSearch = useSelector(selectShowSearch);
    const noLocationSelected = useSelector(selectNoLocationSelected);
    const hasLocationsDefined = useSelector(selectHasLocationsDefined);

    const isLoadingProducts = useSelector(selectIsLoading);
    const statusApiHasFailed = useSelector(selectStatusApiHasFailed);
    const products = useSelector(selectProducts);
    const productsCount = useSelector(selectProductsCount);
    const productsAreFiltered = useSelector(selectProductsAreFiltered);

    const cartHasItems = useSelector(selectCartHasItems);
    const triggerAvailability = useSelector(selectTriggerProductsAvailability);

    const recurring = useSelector(selectRecurring);
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);
    const fromToHasErrors = useSelector(selectFromToHasErrors);

    const recurrenceType = useSelector(selectRecurrenceType);
    const startDateRecurring = useSelector(selectStartDateRecurring);
    const endDateRecurring = useSelector(selectEndDateRecurring);
    const repetitionInterval = useSelector(selectRepetitionInterval);
    const recurrenceStartDay = useSelector(selectRecurrenceStartDay);
    const recurrenceStartTime = useSelector(selectRecurrenceStartTime);
    const recurrenceEndDay = useSelector(selectRecurrenceEndDay);
    const recurrenceEndTime = useSelector(selectRecurrenceEndTime);
    const recurrenceHasErrors = useSelector(selectRecurrenceHasErrors);

    const debouncedGetProductsCount = useDebounceCallback(
        () => {
            if (!(hasLocationsDefined && noLocationSelected)) {
                dispatch(getProductsCount());
            }
        },
        // delay in ms
        Debounce.DEBOUNCE_MIN_TIMEOUT
    );

    useEffect(() => {
        // On initial page load the category from the URL path and the selected category are both empty strings ('')
        // We need to get the products count in this situation
        if ((category === selectedCategory && selectedCategory === '') && startDate && endDate) {
            if (!(hasLocationsDefined && noLocationSelected)) {
                debouncedGetProductsCount()
            }
        }

        // Remove product details if it was previously loaded
        dispatch(removeProduct());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!(/^\d+$/.test(category)) && category !== "") {
            navigate("/items/", { replace: true });
            return;
        }

        dispatch(setCategoryPath(CategoriesUtils.prepareCategoryPath(categories, category)));

        // If the category is changed (from new URL path or by selecting other category) we need to get the products count
        if ((selectedCategory !== category) && startDate && endDate) {
            dispatch(setCategory(category));
            debouncedGetProductsCount()
        }
    }, [dispatch, category, selectedCategory, startDate, endDate, debouncedGetProductsCount, navigate, categories]);

    const debouncedAvailabilityCheck = useDebounceCallback(
        (products) => {
            if (products) {
                dispatch(checkAvailabilityForProducts());
            }
        },
        // delay in ms
        Debounce.DEBOUNCE_TIMEOUT
    );

    useEffect(() => {
        // console.log("useEffect: debouncedAvailabilityCheck, fromToHasErrors: ", fromToHasErrors);

        if (startDate && endDate && (!recurrenceHasErrors) && (!fromToHasErrors)) {
            debouncedAvailabilityCheck(products);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, debouncedAvailabilityCheck, cartHasItems, triggerAvailability, recurring, 
        startDate, endDate, recurrenceType, startDateRecurring, endDateRecurring, 
        repetitionInterval, recurrenceStartDay, recurrenceStartTime, recurrenceEndDay, 
        recurrenceEndTime, recurrenceHasErrors, fromToHasErrors]);

    const handleCancelSelectLocation = () => {
        console.log("handleCancelSelectLocation called.");
    }

    // not state var, avoids reloading products for same page, if handleLoadMore is called multiple times on same instance
    // i guess this is because of key={productsCount} in InfiniteScroll
    var lastPageLoaded = 0;
    const handleLoadMore = (page) => {
        // if locations are defined and no location is selected, do not load more products
        if (hasLocationsDefined && noLocationSelected) {
            return;
        }

        // console.log(`trying loading more: page ${page} (last loaded: ${lastPageLoaded})`);

        // load more products if not loading already, and avoid loading the same page twice
        if (!isLoadingProducts && lastPageLoaded !== page) {
            console.log(`loading more: page ${page}`);

            dispatch(getProducts({ page }));

            lastPageLoaded = page;
        }
    }

    const productsLoadedCount = products ? Object.values(products).length : 0;

    // isLoadingProducts is needed here to put hasMore on false while loading more products
    // this fixes the problem that handleLoadMore is called with a page number while still loading the page before
    // this causes the page to be ignored (handleLoadMore did not load that page but the infinite scroll does not take that into account)
    const hasMore = !statusApiHasFailed && !isLoadingProducts && productsLoadedCount < productsCount;

    // todo: add padding using the css class for columns, see example in docs
    return (
        <Stack sx={{...flexColFullHeightScrollable, ...p1}} direction='column' spacing={2}>
            { !showSearch && !productsAreFiltered && 
                <Box sx={{...flexColCentered}}>
                    { showWelcomeImage && 
                        <Box
                        component="img"
                        sx={{
                            display: 'block',
                            width: '100%',
                            height: 'auto',
                            minHeight: '100px',
                            maxHeight: '500px',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            borderRadius: '10px'
                        }}
                        alt="product list"
                        src={ShopConfig.config().getURLForWelcomeImage(welcomeImage)}
                    /> }
                    { showWelcomeText && 
                        <Box sx={{...flexRowCentered, ...py2}}>
                            <Typography dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(welcomeText) }} ></Typography>
                        </Box>
                    }
                </Box>
            }

            {productsAreFiltered &&
                <Box sx={{ ...flexRowCentered, ...py2 }}>
                    <Typography variant="h5">{productsCount + t("product.list.searchResults")}</Typography>
                </Box>
            }

            <InfiniteScroll
                key={productsCount}
                pageStart={0}
                loadMore={handleLoadMore}
                hasMore={hasMore}
                loader={!(hasLocationsDefined && noLocationSelected) ? <div className="loader" key={0}><Typography variant="body">Loading ...</Typography></div> : null}
                useWindow={false}
            >
                <MasonrySection breakpointCols={{
                    default: 5,
                    980: 1,
                    1310: 2,
                    1640: 3,
                    1960: 4,
                    2290: 5
                }}
                    items={(products ? Object.values(products) : [])}
                    loading={isLoadingProducts}
                    skeleton={ProductCardSkeleton}
                    card={ProductCard} />
            </InfiniteScroll>
            {hasLocationsDefined && noLocationSelected && <NoLocationSelectedDialog open={noLocationSelected} onOk={() => { }} onCancel={handleCancelSelectLocation} showActionBar={false} />}
        </Stack>
    );
}